#home {
  margin-bottom: 80px;

  h1 {
    position: relative;
    margin: 0 10px;
    margin-top: 40px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    text-shadow: -1px 1px 2px rgba(0,0,0,.2);
  }

  .col {
    position: relative;
    width: 300px;
    margin: 0 auto;

    h2 {
      margin: 0 10px;
      margin-top: 40px;
      color: $green;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      text-shadow: -1px 1px 2px rgba(0,0,0,.2);
    }

    #table {

      table {
        width: 100%;

        tr {

          td {
            display: block;
            background-color: $green;

            h3,
            h4,
            p {
              display: inline-block;
              font-size: 14px;
              color: #FFFFFF;
              padding: 5px 10px;
              font-weight: 200;
            }
            span {
              position: relative;
              padding: 5px;
              padding-right: 7px;
              margin-top: -30px;
              display: inline-block;
              float: right;
              font-size: 12px;
              font-weight: 200;
              color: $orange;
            }
            .tableH {
              font-size: 16px;
              color: $orange2;
              font-weight: 600;
              margin-top: 10px;
              margin-left: -5px;
              padding-left: 0;
            }
            h3 {
              font-size: 30px;
              line-height: 34px;
              font-weight: 400;
            }
            .steps {
              position: relative;
              margin-left: 0;
              font-size: 16px;
              line-height: 20px;
              font-weight: 600;
              color: #000000;
            }
          }
          td:nth-child(1) {
            background-color: #FFFFFF;
          }
          td:nth-child(2) {
            border-radius: 10px 10px 0 0;
          }
          td:last-child {
            border-radius: 0 0 10px 10px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .table {
      table {
        width: 100%;

        tr {

          td {
            display: block;
            background-color: $green;

            p {
              display: inline-block;
              font-size: 14px;
              color: #FFFFFF;
              padding: 5px 10px;
              font-weight: 200;
            }
          }
          td:first-child {
            border-radius: 10px 10px 0 0;
          }
          td:last-child {
            border-radius: 0 0 10px 10px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .tables {
      table {
        width: 100%;

        tr {

          td {
            display: block;
            background-color: $green;

            p {
              display: inline-block;
              font-size: 14px;
              color: #FFFFFF;
              padding: 5px 10px;
              font-weight: 200;
            }
          }
          td:first-child {
            border-radius: 10px 10px 0 0;
          }
          td:last-child {
            border-radius: 0 0 10px 10px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .smH {
      color: $orange;
    }
    .sm {
      table tr td:nth-child(even) {
        background-color: $black;
         p {
           font-size: 10px;
           padding: 0 10px;
         }
      }
      table tr td:nth-child(odd) {
        background-color: $orange;
      }
      .last {
        height: 5px;
      }
    }

    .ice {
      table tr td:nth-child(even) {
        background-color: $black;
        p {
          font-size: 10px;
          padding: 0 10px;
        }
      }
      .last {
        height: 5px;
      }
    }

    #bubbleH {
      color: $orange;
    }
    #tableB {
      table {
        width: 100%;

        tr {

          .tableH {
            color: $orange;
            font-size: 16px;
            font-weight: 600;
            margin-top: 10px;
            margin-left: 15px;
          }

          .tableH2 {
            color: $green;
            font-size: 14px;
            font-weight: 600;
          }

          td {
            display: block;
            background-color: $green;

            p {
              display: inline-block;
              font-size: 14px;
              color: #FFFFFF;
              padding: 5px 10px;
              font-weight: 200;
            }
          }

          .tdWBG {
            background: #FFFFFF;
          }

          .tdR {
            display: inline-block;
            width: 140px;
            text-align: center;
            border-radius: 5px;

            p {
              font-weight: 600;
            }

            span {
              position: relative;
              padding: 5px;
              padding-right: 7px;
              margin-top: -60px;
              display: inline-block;
              float: right;
              font-size: 12px;
              font-weight: 200;
              color: $orange;
            }
          }
          .tdR:first-child {
            margin-right: 20px;
          }
        }
        .trB {

          td:nth-child(2) {
            border-radius: 10px 10px 0 0;
          }

          td:last-child {
            border-radius: 0 0 10px 10px;
          }
        }
        .tdRL {
          display: inline-block;
          width: 140px;
          margin-bottom: 20px;

          td:first-child {
            padding-top: 5px;
            border-radius: 5px 5px 0 0;
          }
          td:last-child {
            border-radius: 0 0 5px 5px;
          }
        }
        .tdRLF {
          margin-right: 20px;
        }
        .trF {
          background: #FFFFFF;

          td {
            background: #FFFFFF;
          }
        }
      }

      .stepsB {
        position: absolute;
        margin-left: 10px;
        margin-top: 15px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: #000000;
      }
    }
  }

  .hr {
    position: relative;
    margin: 0 auto;
    margin-top: 20px;
    border: none;
    height: 1px;
    width: 80%;
    background-color: $orange;
  }
}
