.nav {
  position: fixed;
  top: 0;
  right: 0;
  margin-right: -100%;
  margin-top: -100%;
  width: 150px;
  height: 150px;
  background: #FFFFFF;
  box-shadow: 1px 0 6px rgba(0,0,0,.6);
  z-index: 100;

  @include transition(.5s);

  ul {


    li {
      position: absolute;
      bottom: 10px;
      left: 10px;
      text-align: left;

      a {
        display: block;
        padding: 10px;
        color: black;
        font-size: 14px;
      }
    }
  }
}
.nav.active {
  margin-right: 0;
  margin-top: 0;
}

.m-menu {
  display: inline-block;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  padding-top: 10px;
  text-align: center;
  color: $black;
  cursor: pointer;
  line-height: 4px;
  z-index: 200;

  .bar {
    position: absolute;
    top: 10px;
    left: 0;
    width: 25px;
    height: 2px;
    background-color: $orange;
    display: inline-block;
    @include transition(.25s);
  }

  .b2 {
    top: 18px;
  }
  .b3 {
    top: 26px;
  }
}
.m-menu.active {
  .b1,
  .b3 {
    top: 18px;
    background-color: $orange;
  }
  .b2 {
    display: none;
    background-color: $orange;
  }
}
.m-menu.active.cross {
  .b1 {
    transform: rotate(45deg);
  }
  .b3 {
    transform: rotate(-45deg);
  }
}

