#about {
  position: relative;
  top: 30px;
  padding: 20px;
  margin-bottom: 100px;

  h2 {
    color: $green;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  h3 {
    color: $orange;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
}
