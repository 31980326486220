header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background: url('../img/header.jpg') center center no-repeat;
  background-size: cover;

  #aLogo {
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 50px;
    margin-left: -100px;
    margin-top: -50px;

    #logo {
      width: 100%;
      height: 100%;
      background: url('../img/logo.svg') center center no-repeat;
      background-size: cover;
      @include transition(1.5s);
      @include transition-delay(1.5s);
      @include opacity(0);
    }
    #logo.active {
      @include opacity(1);
    }
  }
}

