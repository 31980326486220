/* reset */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,
acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,
strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,
tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;font-weight:inherit;
  font-style:inherit;font-size:100%;font-family:inherit;vertical-align:baseline}:focus{outline:0}
body{font-size:75%;color:black;background:white;line-height:20px}ol,ul{list-style:none}
table{border-collapse:separate;border-spacing:0}caption,th,td{text-align:left;
font-weight:normal}dt{display:none}.c{clear:both}*{box-sizing:border-box;
-webkit-box-sizing:border-box;-ms-box-sizing:border-box;}

* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body,html{
	min-height: 100vh;
}
