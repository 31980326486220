footer {
  position: relative;
  width: 100%;
  height: 170px;
  margin-top: 20px;
  padding: 20px;
  background: $green;

  article {
    position: relative;


    p {
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;

      a {
        color: #FFFFFF;
      }

      i {
        margin-right: 10px;
        font-size: 18px !important;
      }
      .fa-phone {
        margin-right: 7px;
      }
    }
  }

  aside {
    position: absolute;
    width: 200px;
    height: 75px;
    bottom: 17px;
    left: 0;
    cursor: pointer;

    .logo {
      width: 100%;
      height: 100%;
      background: url('./../img/logo.svg') center center no-repeat;
      background-size: cover;
    }
  }

  .copyright {
    position: absolute;
    bottom: 5px;
    right: 10px;
    text-align: right;

    p {
      font-size: 8px;
      line-height: 12px;
      color: #FFFFFF;
    }
  }
}
