@media only screen and (min-width:320px) {

}
@media only screen and (min-width:480px) {

}
@media only screen and (min-width:768px) {
  //.m-menu {
  //  display: none;
  //}
  //.nav {
  //  right: 30px;
  //  margin-right: 0;
  //  margin-top: 0;
  //  width: 210px;
  //  height: auto;
  //  box-shadow: none;
  //  background-color: transparent;
  //
  //
  //  ul li {
  //    position: relative;
  //    bottom: 0;
  //    top: 30px;
  //    right: 30px;
  //    left: 0;
  //    background-color: $orange;
  //    text-align: center;
  //    border-radius: 5px;
  //    box-shadow: -1px 1px 3px 1px rgba(0,0,0,.4);
  //    box-sizing: border-box;
  //
  //    a {
  //      display: inline-block;
  //      padding: 15px;
  //      font-size: 16px;
  //      color: #FFFFFF;
  //      box-sizing: border-box;
  //      @include transition(.25s);
  //
  //      &:hover {
  //        border-bottom: 4px solid $green;
  //        padding-bottom: 11px;
  //      }
  //    }
  //  }
  //}

  header {
    height: 400px;

    #aLogo {
      width: 300px;
      height: 100px;
      margin-left: -150px;
      margin-top: -100px;
    }
  }

  #home {

    h1 {
      font-size: 30px;
      line-height: 30px;
    }

    .hr {
      display: none;
    }

    .col {
      width: 50%;
      float: left;
      margin: 0;
      padding: 0 30px;

      h2 {
        font-size: 22px;
      }

      .table table tr td p,
      #tableB table tr td p,
      #table table tr td h3,
      #table table tr td p {
        padding: 10px;
        font-size: 16px;
      }

      #table table tr td .steps {
        font-size: 30px;
      }

      #table table tr td h3 {
        font-size: 32px;
      }

      .tables table tr td p {
        font-size: 16px;
      }

      .ice table tr td:nth-child(2n) p,
      .sm table tr td:nth-child(2n) p {
        font-size: 12px;
        padding: 2px 10px;
      }

      #tableB table tr .tableH {
        margin-left: 20px;
      }

      #tableB table .tdRL,
      #tableB table tr .tdR {
        width: 46%;
      }

      #table table tr td span,
      #tableB table tr .tdR span {
        font-size: 14px;
        line-height: 10px;
      }
      #tableB table tr .tdR span {
        margin-top: -70px;
      }
      #tableB .stepsB {
        margin-top: 20px;
        font-size: 30px;
      }
    }
  }

  .smoothH {
    margin-bottom: 50px !important;
  }

  #about {

    aside,
    article {
      width: 50%;
      float: left;
      padding: 20px;
    }

    h2 {
      padding: 0 20px;
      font-size: 22px;
      line-height: 26px;
    }

    h3 {
      font-size: 20px;
      line-height: 22px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }


  footer {
    padding: 30px;

    article {
      float: left;

      p {
        font-size: 18px;
        line-height: 32px;
      }
    }

    aside {
      position: relative;
      bottom: 0;
      top: 0;
      float: right;
      width: 250px;
      height: 75px;
      margin-right: -28px;
    }
  }
}
@media only screen and (min-width:1024px) {
  #home {
    position: relative;
    width: 1000px;
    margin: 0 auto;

    .col {
      width: 33.33%;

      #tableB table .tdRL,
      #tableB table tr .tdR {
        width: 46%;
      }

      #tableB table tr .tdR span {
        margin-top: -70px;
      }
      #table table tr td span {
        margin-top: -30px;
      }
    }
  }

  footer .copyright p {
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }

}
@media only screen and (min-width:1140px) {
  #home {
    width: 1100px;
  }
}
@media only screen and (min-width:1280px) {
  #home {
    width: 1200px;
  }
}
