.shadow {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  @include opacity(1);
  @include transition(1s);
  @include transition-delay(1s);
  z-index: 999999;

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    margin-top: -200px;
    margin-left: -200px;
    background: url("./../img/logo.svg") center center no-repeat;
  }
}
.shadow.deactivate {
  @include opacity(0);
}

.shadow.gone {
  display: none;
}
